
import { IonItem, IonIcon, IonSpinner } from "@ionic/vue";
import { defineComponent } from "vue";
import router from "@/router";
import cyanRequest from "@/modules/cyanRequest";
import store from "@/modules/adaptedStorage";

import { checkmarkCircleOutline, closeCircleOutline } from 'ionicons/icons';

export default defineComponent({
  name: "PartyEntry",
  components: {
    IonItem,
    IonIcon,
    IonSpinner
  },
  data() { return {
    loading: false
  }
  },
  props: {
    element: Object,
    altBaseRoute: {
      type: String,
      default : ''
    },
    ignorarAsistencia: {
      type: Boolean,
      default: false
    },
    modoAsistencia: {
      type: Boolean,
      default: false
    },
  },
  setup() {
    return {
      checkmarkCircleOutline, closeCircleOutline
    };
  },
  methods: {
    elementClick(e: Event) {
      const el = this.element as any;
      if (this.modoAsistencia && el.asistenciaMarcable) 
        this.marcarAsistencia(el.asistio);
      else 
        this.openCargo(e);
    },
    openCargo(e: Event) {
      const _e = (this as any).element;
      e.stopPropagation();
      if (!_e.ficticio) {
        const route = ( (this.altBaseRoute == '') ? "/partyMember/" + _e.nivel : this.altBaseRoute) + "/" + _e.id;
        router.replace(route);
      }
      return false;
    },
        marcarAsistencia(desmarcar: boolean) {
        const _t = this as any;
        _t.loading = true;
        cyanRequest(
        (desmarcar ? "jrsl/unsetPresencia/" : "jrsl/setPresencia/") +
          (this as any).element.id,

        {
          needsAuth: true,
          isPost: true,
          isModal: false
        }
      ).then(function (d) {
        if (d.ok) {
          store.commit("storeAsistenciaStreamlined", d);
        }
        _t.loading = false;
      });
    },

  },
});
