<template>
  <ion-item @click="elementClick" lines="none">
    <!-- eslint-disable vue/no-deprecated-slot-attribute -->

    <div class="cargoWrapper" :class="{ ficticio: element.ficticio }">
      <div class="cargoCargo">

        <span class="iconoAsistencia" v-if="element.asistenciaMarcable && !ignorarAsistencia">

        <ion-spinner color="primary" class="cargoAsistencia cargoSpinner" v-if="loading"></ion-spinner>
        <ion-icon :icon="checkmarkCircleOutline" class="cargoAsistencia cargoAsistio" v-else-if="element.asistio"></ion-icon>
        <ion-icon :icon="closeCircleOutline" class="cargoAsistencia cargoNoAsistio" v-else></ion-icon>

        </span>

        {{ element.nombre_cargo }}
      </div>
      <div class="cargoPersona" @click="openCargo">
        <div class="cargoImagen"><img :src="element.image || 'assets/images/PersonaSinFoto.svg'" /></div>
        <div class="cargoNombre">{{ element.nombre }}</div>
      </div>
    </div>
  </ion-item>
</template>

<script lang="ts">
import { IonItem, IonIcon, IonSpinner } from "@ionic/vue";
import { defineComponent } from "vue";
import router from "@/router";
import cyanRequest from "@/modules/cyanRequest";
import store from "@/modules/adaptedStorage";

import { checkmarkCircleOutline, closeCircleOutline } from 'ionicons/icons';

export default defineComponent({
  name: "PartyEntry",
  components: {
    IonItem,
    IonIcon,
    IonSpinner
  },
  data() { return {
    loading: false
  }
  },
  props: {
    element: Object,
    altBaseRoute: {
      type: String,
      default : ''
    },
    ignorarAsistencia: {
      type: Boolean,
      default: false
    },
    modoAsistencia: {
      type: Boolean,
      default: false
    },
  },
  setup() {
    return {
      checkmarkCircleOutline, closeCircleOutline
    };
  },
  methods: {
    elementClick(e: Event) {
      const el = this.element as any;
      if (this.modoAsistencia && el.asistenciaMarcable) 
        this.marcarAsistencia(el.asistio);
      else 
        this.openCargo(e);
    },
    openCargo(e: Event) {
      const _e = (this as any).element;
      e.stopPropagation();
      if (!_e.ficticio) {
        const route = ( (this.altBaseRoute == '') ? "/partyMember/" + _e.nivel : this.altBaseRoute) + "/" + _e.id;
        router.replace(route);
      }
      return false;
    },
        marcarAsistencia(desmarcar: boolean) {
        const _t = this as any;
        _t.loading = true;
        cyanRequest(
        (desmarcar ? "jrsl/unsetPresencia/" : "jrsl/setPresencia/") +
          (this as any).element.id,

        {
          needsAuth: true,
          isPost: true,
          isModal: false
        }
      ).then(function (d) {
        if (d.ok) {
          store.commit("storeAsistenciaStreamlined", d);
        }
        _t.loading = false;
      });
    },

  },
});
</script>

<style scoped>
.cargoWrapper {
  cursor: pointer;
  padding: 0 15px;
  background: white;
  width: 100%;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  border: 1px var(--cyan-light-grey) solid;
  display: flex;
}
.cargoWrapper.ficticio {
  cursor: initial;
}
.cargoWrapper div {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.cargoCargo,
.cargoPersona {
  flex: 1 0 100px;
}

.cargoCargo {
  color: var(--ion-color-primary);
  font-weight: 500;
}

.cargoPersona {
  display: flex;
  align-items: center;
  color: var(--ion-color-secondary);
}

.cargoImagen {
  width: 42px;
  height: 32px;
  text-align: center;
  padding: 0 5px;
  flex: 0 0 auto;
}
.cargoImagen img {
  display: block;
  max-width: 32px;
  max-height: 32px;
  margin: 0 auto;
}
ion-item {
  --min-height: 0;
  margin: 5px 0;
}

.cargoAsistio {
  color: var(--ion-color-success);
}
.cargoNoAsistio {
  color: var(--ion-color-danger);
}
.cargoInfo {
  color: var(--ion-color-primary);
}

.cargoAsistencia {
  font-size: 120%;
  position: relative;
  top: 3px;
  width: 20px;
  height: 20px;
}


@media (max-width: 400px) {
  .cargoWrapper {
    flex-wrap: wrap;
    border-radius: 25px;
  }
  .cargoCargo,
  .cargoPersona {
  flex: 1 0 200px;
  }

  .cargoCargo {
    padding-top: 5px;
  }

  .cargoPersona {
    justify-content: space-between;
  }

  .cargoImagen {
    order: 3;
    flex: 0 0 auto;
    padding-right: 0;
  }

}
</style>